// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-agency-index-jsx": () => import("./../../../src/templates/agency/index.jsx" /* webpackChunkName: "component---src-templates-agency-index-jsx" */),
  "component---src-templates-contact-index-jsx": () => import("./../../../src/templates/contact/index.jsx" /* webpackChunkName: "component---src-templates-contact-index-jsx" */),
  "component---src-templates-news-list-index-jsx": () => import("./../../../src/templates/newsList/index.jsx" /* webpackChunkName: "component---src-templates-news-list-index-jsx" */),
  "component---src-templates-project-index-jsx": () => import("./../../../src/templates/project/index.jsx" /* webpackChunkName: "component---src-templates-project-index-jsx" */),
  "component---src-templates-project-list-index-jsx": () => import("./../../../src/templates/projectList/index.jsx" /* webpackChunkName: "component---src-templates-project-list-index-jsx" */)
}

